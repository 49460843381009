import store from '@/store';

const youtubesearchapi = require('youtube-search-api');

const uniqueIdGenerate = (str) => str + Math.random().toString(36).substr(2, 9);
const Test = () => 'Test';

const downloadData = (data) => {
  const eventPayload = {
    verb: 'DOWNLOAD_GRAPH',
    value: {
      title: data.nodes?.[0]?.id !== undefined ? data.nodes[0].id : data.id,

    },
  };
  store.dispatch('eventToEventStore', eventPayload);
  store.dispatch('actionFeedback', eventPayload);
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.json';
  a.click();
  document.body.removeChild(a);
};

const youtubeSearch = async (word) => {
  const youtubeResponse = await youtubesearchapi.GetListByKeyword(word, 10);
  return youtubeResponse;
};

const youtubeSearchNextPage = async (nextPage) => {
  const youtubeResponse = await youtubesearchapi.NextPage(nextPage, [false], [10]);
  return youtubeResponse;
};

export {
  uniqueIdGenerate,
  Test,
  downloadData,
  youtubeSearch,
  youtubeSearchNextPage,
};
